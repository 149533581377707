"use strict";

/**
 * jQuery
 */
// Expose jQuery globals on window.
window.jQuery = window.$ = require("jquery")


/**
 * Bootstrap
 */
// Fixes "process is not defined" error when using Popper.
// Requires project dependency on NPM package "process".
window.process = require('process/browser')

// Require (rather than import) Bootstrap
// after jQuery to make sure that jQuery globals
// are available and Bootstrap can register its plugins.
// NOTE: Expose as window object property for bootstrap-select.
window.bootstrap = require("bootstrap")

require("./css/bootstrap.scss")  // customized


/**
 * DataTables + Bootstrap 5 theme
 */
require("datatables.net")
require("datatables.net-bs5")
require("datatables.net-bs5/css/dataTables.bootstrap5.css")


/**
 * Select2 + Bootstrap 5 theme
 */
require("select2")
require("select2/src/scss/core.scss")
require("./css/select2-bootstrap-5-theme.scss")  // customized

$(function () {
  // Replace 'input.select' matches with select controls
  // to be enhanced with Select2 integration below.
  $("input.select").replaceWith(function () {
    const $input = $(this);

    // Replace with a select containing a single
    // option representing the current value.
    const $select = $("<select>").append(
      $("<option>")
        .prop("selected", true)
        .text($input.val())
        .val($input.val())
    );

    // Copy attributes from input to select element.
    $.each(this.attributes, function (i, att) {
      $select.attr(att.name, att.value);
    });

    $select
      .removeClass('form-control')
      .addClass('form-select');

    return $select;
  });

  $("select").select2({
    debug:true,

    // Use Bootstrap 5 theme.
    theme: "bootstrap-5",

    // Allow dropdown to be wider than
    // select element to fit wide options.
    dropdownAutoWidth: true,

    // ajax: {
    //   delay: 200,
    // }
  });
});


/**
 * Site CSS.
 */
require("./css/main.scss")
